<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">
        {{ isEditing ? "Edit" : "Add a new" }} Value
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="value-form">
          <v-text-field
            label="Value"
            v-model="fields.value"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('value')"
            :error-messages="errors['value']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="value-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      form: {},
      errors: {},
      fields: {
        value: null,
      },
    };
  },

  computed: {},

  methods: {
    open(form = null) {
      this.form = form;
      this.isEditing = true;
      this.fields.value = form.value;

      this.dialog = true;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        submissionId: this.form.id,
        fields: this.fields,
      };

      this.$store
        .dispatch("contactForms/storeValue", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.form = {};
      this.fields.value = null;

      this.$refs.formWrapper.scrollTop = 0;
    },
  },
};
</script>
