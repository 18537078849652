<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="12" class="pr-12">
          <h1>Submissions</h1>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-row align="center">
          <v-col lg="6" sm="12">
            <v-row align="center">
              <v-col lg="6" sm="12">
                <v-text-field
                  label="Search Submissions"
                  v-model="searchTerm"
                  outlined
                  dense
                  hide-details
                  prepend-inner-icon="mdi-magnify"
                ></v-text-field>
              </v-col>
              <v-col lg="6" sm="12">
                <v-select
                  v-if="formNames.length"
                  label="Form"
                  outlined
                  dense
                  :items="formNames"
                  item-text="form_name"
                  item-value="form_name"
                  v-model="searchFormName"
                  background-color="white"
                  hide-details
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col lg="6" sm="12">
            <v-col sm="12" lg="12" class="text-right">
              <v-row align="center">
                <v-col cols="4">
                  <v-text-field
                    type="date"
                    v-model="fields.start_date"
                    outlined
                    dense
                    hide-details
                    background-color="white"
                  ></v-text-field>
                </v-col>
                <v-col cols="auto">to</v-col>
                <v-col cols="4">
                  <v-text-field
                    type="date"
                    v-model="fields.end_date"
                    outlined
                    dense
                    hide-details
                    background-color="white"
                  ></v-text-field>
                </v-col>
                <v-col cols="auto">
                  <v-btn
                    depressed
                    color="green lighten-4 green--text"
                    @click="loadFiltredSubmissions"
                  >
                    Filter
                  </v-btn></v-col
                >
              </v-row>
            </v-col>
          </v-col>
        </v-row>
      </v-row>

      <v-row align="center">
        <v-col>
          <v-btn
            v-if="hasPermission('view-module-contactform-deluxe', currentAppId)"
            color="blue lighten-4 blue--text"
            class="mr-2"
            depressed
            light
            :to="{ name: 'module-contactforms-stats' }"
          >
            Stats
          </v-btn>

          <v-btn
            v-if="currentUserRole.name == 'VH Superadmin'"
            color="green lighten-4 green--text"
            class="mr-2"
            depressed
            light
            @click="openExportDialog()"
          >
            CSV
          </v-btn>

          <v-btn
            depressed
            color="warning"
            class="mr-2"
            :to="{ name: 'module-contactforms-recipients' }"
            ><v-icon left>mdi-email-send</v-icon> Recipients</v-btn
          >
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                depressed
                light
                v-on="on"
                :to="{ name: 'module-contactforms-archived' }"
              >
                <v-icon small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>View Archived Submissions</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-data-table
        :headers="showHeaders"
        :items="submissions"
        no-data-text="There are currently no submissions"
      >
        <template v-slot:item.page_url="{ item }"
          >/{{ item.page_url }}</template
        >
        <template v-slot:item.created_at="{ item }">
          {{ item.formatted_dates.created_at }}
        </template>

        <template v-slot:item.type="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                v-on="on"
                :loading="typeUpdating === item.id"
              >
                <span v-if="item.type">{{ item.type.name }}</span>
                <v-icon x-small right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                v-for="(type, index) in listTypes"
                :key="index"
                @click="updateType(item.id, type.id)"
              >
                <v-list-item-title>{{ type.name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>

        <template v-slot:item.source="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                v-on="on"
                :loading="sourceUpdating === item.id"
              >
                <span v-if="item.source">{{ item.source.name }}</span>
                <v-icon x-small right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                v-for="(source, index) in listSources"
                :key="index"
                @click="updateSource(item.id, source.id)"
              >
                <v-list-item-title>{{ source.name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn
            v-if="hasPermission('view-module-contactform-deluxe', currentAppId)"
            x-small
            depressed
            color="blue lighten-4 blue--text"
            class="mr-2"
            @click="$refs.valueDialog.open(item)"
          >
            <v-icon x-small>£</v-icon>
          </v-btn>

          <v-btn
            x-small
            depressed
            color="green lighten-4 green--text"
            class="mr-2"
            @click="openSubmissionDialog(item)"
          >
            <v-icon x-small left>mdi-eye</v-icon> View
          </v-btn>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="red lighten-4 red--text"
                v-on="on"
                @click="openDeleteDialog(item)"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>Archive</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog
      v-model="submissionDialog.open"
      scrollable
      max-width="1000"
      @click:outside="resetSubmissionDialog()"
    >
      <v-card>
        <v-card-title class="headline">View Submission</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5 grey lighten-4">
          <v-simple-table>
            <tbody>
              <tr>
                <th>Full Name</th>
                <td>{{ submissionDialog.submission.full_name }}</td>
              </tr>
              <tr>
                <th>Email</th>
                <td>{{ submissionDialog.submission.email }}</td>
              </tr>
              <tr
                v-for="(input, name) in submissionDialog.submission.content"
                :key="name"
              >
                <th
                  class="pull-left"
                  v-if="name.substring(0, 12) == 'Filemultiple'"
                >
                  {{ name.substring(12, 100) }}
                </th>
                <th
                  class="pull-left"
                  v-else-if="name.substring(0, 12) == 'File'"
                >
                  {{ name.substring(4, 100) }}
                </th>
                <th class="pull-left" v-else>{{ name }}</th>
                <td
                  v-if="name.substring(0, 12) == 'Filemultiple'"
                  style="padding-top: 8px; padding-bottom: 8px"
                >
                  <div v-for="file in splitJoin(input)" :key="file">
                    <a target="_blank" :href="`${file}`">
                      {{ file }}
                    </a>
                  </div>
                </td>
                <td
                  v-else-if="name.substring(0, 4) == 'File'"
                  style="padding-top: 8px; padding-bottom: 8px"
                >
                  <a target="_blank" :href="`${input}`">
                    {{ input }}
                  </a>
                </td>
                <td
                  v-else
                  style="padding-top: 8px; padding-bottom: 8px"
                  v-html="input"
                ></td>
              </tr>
            </tbody>
          </v-simple-table>
          <v-simple-table class="mt-5">
            <tbody>
              <tr>
                <th>Submitted At</th>
                <td>
                  {{ submissionDialog.submission.formatted_dates.created_at }}
                </td>
              </tr>
              <tr>
                <th>Form Name</th>
                <td>{{ submissionDialog.submission.form_name }}</td>
              </tr>
              <tr>
                <th>IP Address</th>
                <td>{{ submissionDialog.submission.ip_address }}</td>
              </tr>
              <tr>
                <th>Page URL</th>
                <td>/{{ submissionDialog.submission.page_url }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="resetSubmissionDialog()"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialog.open" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Delete Submission</v-card-title>
        <v-card-text
          >Are you sure you wish to delete the submission from
          {{ deleteDialog.submission.full_name }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDeleteDialog"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="deleteDialog.loading"
            @click="saveDeleteDialog"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="exportDialog.open" persistent max-width="600">
      <v-card>
        <v-card-title class="headline"
          >Export date range <v-spacer></v-spacer>
          <v-btn icon @click="resetExportDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn></v-card-title
        >
        <v-card-text
          ><p>Export all data within selected date range</p>

          <v-row align="center">
            <v-col cols="4">
              <v-text-field
                type="date"
                v-model="fieldsExport.start_date"
                outlined
                dense
                hide-details
                background-color="white"
              ></v-text-field>
            </v-col>
            <v-col cols="auto">to</v-col>
            <v-col cols="4">
              <v-text-field
                type="date"
                v-model="fieldsExport.end_date"
                outlined
                dense
                hide-details
                background-color="white"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-btn
                depressed
                color="green lighten-4 green--text"
                @click="downloadItem()"
              >
                Export CSV
              </v-btn></v-col
            >
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <value-dialog ref="valueDialog" />
  </div>
</template>

<script>
import moment from "moment";
import ValueDialog from "./components/ValueDialog.vue";

export default {
  components: {
    ValueDialog,
  },

  data() {
    return {
      searchTerm: "",
      searchFormName: "",
      breadcrumbs: [
        {
          text: "Contact Forms",
          disabled: true,
        },
      ],

      exportDialog: {
        open: false,
      },
      submissionDialog: {
        open: false,
        submission: {
          full_name: null,
          email: null,
          content: [],
          form_name: null,
          page_url: null,
          formatted_dates: {
            created_at: null,
          },
        },
      },
      selectedHeaders: [],
      sourceUpdating: null,
      typeUpdating: null,
      deleteDialog: {
        open: false,
        submission: {},
        loading: false,
      },

      fields: {
        start_date: null,
        end_date: null,
      },
      fieldsExport: {
        start_date: null,
        end_date: null,
      },
    };
  },

  computed: {
    showHeaders() {
      let headers = [];

      headers.push(
        { text: "Full Name", value: "full_name", show: true },
        { text: "Email", value: "email" },
        { text: "Form Name", value: "form_name" },
        { text: "Page URL", value: "page_url" }
      );

      if (
        this.hasPermission(
          "view-module-contactform-deluxe",
          this.$route.params.id
        )
      ) {
        headers.push(
          { text: "Type", value: "type" },
          { text: "Source", value: "source" },
          { text: "Value", value: "value" }
        );
      }

      headers.push(
        { text: "Submitted At", value: "created_at" },
        { text: "Actions", value: "actions", sortable: false, align: "right" }
      );

      return headers;
    },

    currentUserRole() {
      let appId = this.$route.params.id;
      let roles = this.$store.getters.roles;

      roles = roles.filter(function (app) {
        return app.app_id == appId;
      });

      return roles[0];
    },

    currentAppId() {
      return this.$route.params.id;
    },

    submissions() {
      let submissions = this.$store.state.contactForms["submissions"];

      if (this.searchTerm !== "" || this.searchFormName !== "") {
        submissions = submissions.filter((s) => {
          const name = s.full_name.toLowerCase();
          const email = s.email.toLowerCase();
          const formName = s.form_name.toLowerCase();
          const pageUrl = s.page_url.toLowerCase();
          const searchTerm = this.searchTerm.toLowerCase();
          const searchFormName = this.searchFormName;
          const type = s.form_name;

          return (
            (name.includes(searchTerm) ||
              email.includes(searchTerm) ||
              formName.includes(searchTerm) ||
              pageUrl.includes(searchTerm)) &&
            type.includes(searchFormName)
          );
        });
      }

      return submissions;
    },

    formNames() {
      let formNames = this.$store.state.contactForms["formNames"];
      return formNames;
    },

    listTypes() {
      return this.$store.state.contactForms["listTypes"];
    },

    listSources() {
      return this.$store.state.contactForms["listSources"];
    },
  },

  methods: {
    loadFiltredSubmissions: function () {
      let payload = {
        appId: this.$route.params.id,
        fields: this.fields,
      };

      this.$store
        .dispatch("contactForms/loadFiltredSubmissions", payload)
        .then(() => {})
        .catch(() => {});
    },

    updateType: function (submissionId, type) {
      const appId = this.$route.params.id;
      this.typeUpdating = submissionId;

      this.$store
        .dispatch("contactForms/assignType", {
          appId,
          submissionId,
          type,
        })
        .then(() => {
          this.typeUpdating = null;
        });
    },

    updateSource: function (submissionId, source) {
      const appId = this.$route.params.id;
      this.sourceUpdating = submissionId;

      this.$store
        .dispatch("contactForms/assignSource", {
          appId,
          submissionId,
          source,
        })
        .then(() => {
          this.sourceUpdating = null;
        });
    },

    splitJoin(theText) {
      return theText.split(", ");
    },

    downloadItem() {
      let payload = {
        appId: this.$route.params.id,
        fields: this.fieldsExport,
      };

      this.$store
        .dispatch("contactForms/downloadCsv", payload)
        .then((response) => {
          let blob = new Blob([response.data], { type: "text/csv" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "submissions.csv";
          link.click();
        })
        .catch(() => {});
    },

    openSubmissionDialog(submission) {
      this.submissionDialog.submission = submission;
      this.submissionDialog.open = true;
    },

    openExportDialog() {
      this.exportDialog.open = true;

      this.fieldsExport.start_date = moment()
        .subtract(1, "months")
        .format("YYYY-MM-DD");
      this.fieldsExport.end_date = moment().format("YYYY-MM-DD");
    },

    resetExportDialog() {
      this.exportDialog.open = false;
    },

    resetSubmissionDialog() {
      this.submissionDialog.open = false;
      this.submissionDialog.submission = {
        full_name: null,
        email: null,
        content: [],
        form_name: null,
        page_url: null,
        formatted_dates: {
          created_at: null,
        },
      };
    },

    getSubmissionInput(input) {
      if (this.submissionDialog.submission.hasOwnProperty(input)) {
        return this.submissionDialog.submission[input];
      }

      return null;
    },

    openDeleteDialog(submission) {
      this.deleteDialog.submission = submission;
      this.deleteDialog.open = true;
    },

    resetDeleteDialog() {
      this.deleteDialog.open = false;
      this.deleteDialog.submission = {};
      this.deleteDialog.loading = false;
    },

    saveDeleteDialog() {
      this.deleteDialog.loading = true;

      this.$store
        .dispatch("contactForms/deleteSubmission", {
          appId: this.$route.params.id,
          submissionId: this.deleteDialog.submission.id,
        })
        .then(() => {
          this.resetDeleteDialog();
        })
        .catch(() => {
          this.deleteDialog.loading = false;
        });
    },
  },
};
</script>
